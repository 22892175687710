import axios from 'axios'
import router from "../router/index"
import { ElMessage } from "element-plus"
function linkPost (method, url, token, params) {
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: url,
            headers: token,
            data: params
        }).then(r => {
            if (r.data.code == 10401) {
                router.push("/")
                ElMessage({ message: r.data.msg, type: "warning", center: true })
            } else {
                resolve(r.data)
            }
        }).catch(c => {
            reject(c.data)
        })
    })
}

export default linkPost

