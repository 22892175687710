<template>
   <div class="login">
      <div class="login-wrap">
         <div class="login-wrap-left">
            <div class="top">
               <img src="../assets/img/logo.png" alt />
               <span>织逾销CRM</span>
            </div>
            <div class="bottom"></div>
         </div>
         <div class="login-wrap-right">
            <div class="title1">织逾销CRM</div>
            <div class="title2">客户管理系统</div>
            <div class="title3">Customer management system</div>
            <div class="content">
                <template v-if="!pwdLogin">
                    <div class="top">
                        <span>
                            <input type="number" @keyup.enter="login()" @input="userInput" v-model="user" placeholder="请输入手机号" />
                        </span>
                    </div>
                    <div class="bottom">
                        <span>
                            <input type="number" @keyup.enter="login()" @input="codeInput" v-model="code" placeholder="请输入验证码" />
                            <p @click="getcode()" class="box">{{ codeText }}</p>
                        </span>
                    </div>
                </template>
                <template v-else>
                    <div class="top">
                        <span>
                            <input type="number" @keyup.enter="login()" @input="userInput" v-model="user" placeholder="请输入手机号" />
                        </span>
                    </div>
                    <div class="bottom">
                        <span>
                            <el-input maxlength="20" type="number" @keyup.enter="login()" :show-password="true" v-model.trim="password" placeholder="请输入密码" />
                        </span>
                    </div>
                </template>
               <div class="bottom2" style="display: flex;justify-content: space-between;">
                    <span></span>
                    <span @click="changeLoginType()" style="color:#666;cursor: pointer;">{{ pwdLogin?'验证码登录':'密码登录' }}</span>
               </div>
               
               <div class="btn" @click="login()">登录</div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import { mapState, mapMutations } from "vuex"
export default {
    data() {
        return {
            codeText: "获取验证码",
            user: "",
            code: "",
            timer: null,
            count: 60,
            exportUrl: "",
            subUrl: "",
            pwdLogin:false,
            password:''
        }
    },
    created() {
        let url = window.location.href
        if (url == "http://testzyxccrm.zhiyunjishu.cn/") {
            this.exportUrl = "http://testzyxccrm.zhiyunjishu.cn"
            this.subUrl = "http://123.57.156.251:8100/sub"
            window.localStorage.setItem("exportUrl", this.exportUrl)
            window.localStorage.setItem("subUrl", this.subUrl)
        } else if (url == "http://szmccrm.yuluojishu.com/") {
            this.exportUrl = "http://szmccrm.yuluojishu.com"
            this.subUrl = "http://47.96.224.181:8100/sub"
            window.localStorage.setItem("exportUrl", this.exportUrl)
            window.localStorage.setItem("subUrl", this.subUrl)
        } else {
            this.exportUrl = "http://testzyxccrm.zhiyunjishu.cn"
            this.subUrl = "http://123.57.156.251:8100/sub"
            window.localStorage.setItem("exportUrl", this.exportUrl)
            window.localStorage.setItem("subUrl", this.subUrl)
        }
    },
    methods: {
        ...mapMutations(["loginSuccess"]),
        changeLoginType(){
            this.pwdLogin=!this.pwdLogin
            this.password=""
            this.code=""
            this.user=""
        },
        userInput() {
            this.user = this.user + ""
            if (this.user.length > 11) this.user = this.user.slice(0, 11)
        },
        codeInput() {
            this.code = this.code + ""
            if (this.code.length > 6) this.code = this.code.slice(0, 6)
        },
        // 验证码
        getcode() {
            if (this.codeText == "获取验证码") {
                let phonereg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
                if (!phonereg.test(this.user)) {
                    return this.$message.warning("请输入正确的手机号!")
                } else {
                    this.count = 60
                    this.$axios({
                        method: "POST",
                        url: "/admin.api.captcha/getCaptcha",
                        data: {
                            phone: this.user,
                            type: 1,
                        },
                    }).then((res) => {
                        if (res.data.code == 0) {
                            this.$message.success(res.data.msg)
                            this.timer = setInterval(() => {
                                if (this.count > 0 && this.count <= 60) {
                                    this.count--
                                    this.codeText = this.count + "秒后获取"
                                } else {
                                    clearInterval(this.timer)
                                    this.timer = null
                                    this.codeText = "获取验证码"
                                }
                            }, 1000)
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    })
                }
            } else {
                this.$message.warning("请勿重复获取验证码!")
            }
        },
        // 登录
        login() {
            if (this.user) {
                let phonereg = /^1[3-9]\d{9}$/
                if (!phonereg.test(this.user)) {
                    return this.$message.warning("请输入正确的手机号!")
                } else {
                    if(!this.pwdLogin){
                        if (this.code) {
                            this.$axios({
                                method: "POST",
                                url: "/admin.api.user/login",
                                data: {
                                    phone: this.user,
                                    captcha: this.code,
                                },
                            }).then((res) => {
                            res.data.data.time = res.data.time;
                            
                            console.log(res);
                            window.localStorage.setItem('type',res.data.data.type)
                           
                                if (res.data.code == 0) {
                                  
                                    this.$message.success(res.data.msg)
                                    if (res.data.data.type==4) {
                                        this.$router.push("/service/assistant")
                                    } else {
                                        this.$router.push("/handoff")
                                    }
                                    this.loginSuccess(res.data.data)
                                    window.localStorage.setItem("token", res.data.data.token)
                                    this.$cookies.set("token", res.data.data.token, 3 * 24 * 60 * 60)
                                } else {
                                    this.$message.error(res.data.msg)
                                    this.code = ""
                                }
                            })
                        } else {
                            this.$message.warning("验证码不能为空!")
                        }
                    }else{
                        if (this.password) {
                            if(this.password.length<6){
                                return this.$message.warning('登录密码应在6-20位之间')
                            }
                            this.$axios({
                                method: "POST",
                                url: "/admin.api.user/pwdLogin",
                                data: {
                                    login_mobiles: this.user,
                                    password: this.password,
                                },
                            }).then((res) => {
                                window.localStorage.setItem('type',res.data.data.type)
                                if (res.data.code == 0) {
                                 
                                    this.$message.success(res.data.msg)
                                    if (res.data.data.type==4) {
                                        this.$router.push("/service/assistant")
                                    } else {
                                        this.$router.push("/handoff")
                                    }
                                    this.loginSuccess(res.data.data)
                                    window.localStorage.setItem("token", res.data.data.token)
                                    this.$cookies.set("token", res.data.data.token, 3 * 24 * 60 * 60)
                                } else {
                                    this.$message.error(res.data.msg)
                                    this.code = ""
                                }
                            })
                        } else {
                            this.$message.warning("密码不能为空!")
                        }
                    }
                    
                }
            } else {
                this.$message.warning("手机号不能为空!")
            }
        },
    },
}
</script>

<style lang="less" scoped>
.login{
    /deep/ .el-input__inner{
        width: 100%;
        height: 63px;
        border-radius: 100px;
        text-indent: 2em;
        font-size: 1.1vw;
        outline: none;
        border: 1px solid #ccc;
    }
    /deep/ .el-input__inner::placeholder {
        color: rgb(117, 117, 117); //提示文字颜色
    }
    /deep/ .el-input__suffix{
        top: 11px;
    }
    .bottom2 {
        width: 500px;
        margin-top: 20px;
        display: flex;

    }
}


.login {
    width: 100vw;
    height: 100vh;
    background-color: #fff;

    /deep/ input::-webkit-outer-spin-button,
    /deep/ input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }

    /deep/ input[type="number"] {
        -moz-appearance: textfield !important;
    }

    @media only screen and (min-width: 1000px) {
        background: url("http://cdnwm.yuluojishu.com/uploads/20230703/4ad7c37e5602f503d987d81450890efa.png") no-repeat center/cover;

        &-wrap {
            width: 100vw;
            height: 100vh;
            overflow: hidden;

            &-left {
                float: left;
                width: 60vw;
                height: 100vh;

                .top {
                    width: 40vw;
                    height: 40px;
                    display: flex;
                    margin: 50px auto;

                    img {
                        width: 70px;
                        height: 40px;
                        background: no-repeat center/cover;
                    }

                    span {
                        line-height: 40px;
                        font-size: 30px;
                        margin-left: 10px;
                    }
                }

                .bottom {
                    width: 40vw;
                    height: 35vw;
                    margin: 0 auto;
                }
            }

            &-right {
                float: left;
                width: 40vw;
                height: 100%;

                .title1 {
                    width: 30vw;
                    height: 100px;
                    margin: 4vw auto;
                    line-height: 100px;
                    font-size: 3.6vw;
                    color: #2d3f7e;
                    font-weight: bold;
                }

                .title2 {
                    width: 30vw;
                    height: 70px;
                    margin: 0 auto;
                    line-height: 70px;
                    font-size: 2.6vw;
                    color: #2d3f7e;
                }

                .title3 {
                    width: 30vw;
                    height: 40px;
                    margin: 0 auto;
                    line-height: 40px;
                    font-size: 1.8vw;
                    color: #101d4c;
                }

                .content {
                    width: 30vw;
                    height: auto;
                    margin: 0 auto;
                    margin-top: 3vw;

                    .top {
                        width: 100%;
                        height: 80px;
                        line-height: 80px;
                        display: flex;

                        span {
                            width: 500px;
                            height: 60px;

                            input {
                                border: none;
                                width: 100%;
                                height: 100%;
                                border-radius: 100px;
                                text-indent: 2em;
                                font-size: 1.1vw;
                                outline: none;
                                border: 1px solid #ccc;

                                &:focus {
                                    border: 1px solid #409eff;
                                }
                            }
                        }
                    }

                    .bottom {
                        width: 100%;
                        height: 80px;
                        line-height: 80px;
                        margin-top: 2vw;
                        display: flex;

                        span {
                            width: 500px;
                            height: 60px;
                            position: relative;

                            input {
                                border: none;
                                width: 100%;
                                height: 100%;
                                border-radius: 100px;
                                text-indent: 2em;
                                font-size: 1.1vw;
                                outline: none;
                                border: 1px solid #ccc;

                                &:focus {
                                    border: 1px solid #409eff;
                                }
                            }

                            .box {
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 150px;
                                height: 40px;
                                margin-top: 20px;
                                margin-left: 50px;
                                line-height: 40px;
                                text-align: center;
                                font-size: 1.1vw;
                                border-radius: 5px;
                                user-select: none;
                                cursor: pointer;
                                color: #409eff;
                            }
                        }
                    }

                    .btn {
                        width: 250px;
                        height: 76px;
                        margin: 0 auto;
                        line-height: 76px;
                        text-align: center;
                        background-color: #3f69ff;
                        color: #fff;
                        font-size: 1.5vw;
                        border-radius: 100px;
                        margin-top: 3vw;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        &-wrap {
            width: 100vw;
            height: 100vh;
            overflow-y: auto;
            overflow-x: hidden;

            &-left {
                width: 100vw;
                height: 120vw;
                border-top: 1px solid transparent;
                background: url("http://cdnwm.yuluojishu.com/uploads/20230703/c40a03148fb7f826b60ea8d6756af747.png") no-repeat center/cover;

                .top {
                    width: 80%;
                    height: 40px;
                    display: flex;
                    margin: 50px auto;

                    img {
                        width: 40px;
                        height: 40px;
                        background: no-repeat center/cover;
                    }

                    span {
                        line-height: 40px;
                        font-size: 30px;
                        margin-left: 10px;
                    }
                }

                .bottom {
                    width: 100vw;
                    height: 90vw;
                    margin: 0 auto;
                    background: url("../assets/img/back2.png") no-repeat center/cover;
                }
            }

            &-right {
                width: 100vw;
                height: 100vw;

                .title1 {
                    width: 80%;
                    height: 120px;
                    margin: 0 auto;
                    line-height: 120px;
                    font-size: 60px;
                    margin-top: 130px;
                    color: #2d3f7e;
                    font-weight: bold;
                }

                .title2 {
                    width: 80%;
                    height: 70px;
                    margin: 0 auto;
                    line-height: 70px;
                    font-size: 50px;
                    color: #2d3f7e;
                }

                .title3 {
                    width: 80%;
                    height: 40px;
                    margin: 0 auto;
                    line-height: 40px;
                    font-size: 34px;
                    color: #101d4c;
                }

                .content {
                    width: 80%;
                    height: auto;
                    margin: 0 auto;
                    padding-bottom: 40px;
                    margin-top: 100px;

                    .top {
                        width: 100%;
                        height: 80px;
                        line-height: 80px;
                        display: flex;

                        span {
                            width: 100%;
                            height: 60px;

                            input {
                                border: none;
                                width: 100%;
                                height: 100%;
                                border-radius: 100px;
                                text-indent: 2em;
                                font-size: 20px;
                                outline: none;
                                border: 1px solid #ccc;

                                &:focus {
                                    border: 1px solid #409eff;
                                }
                            }
                        }
                    }

                    .bottom {
                        width: 100%;
                        height: 80px;
                        line-height: 80px;
                        display: flex;

                        span {
                            width: 100%;
                            height: 60px;
                            position: relative;

                            input {
                                border: none;
                                width: 100%;
                                height: 100%;
                                border-radius: 100px;
                                text-indent: 2em;
                                font-size: 20px;
                                outline: none;
                                border: 1px solid #ccc;

                                &:focus {
                                    border: 1px solid #409eff;
                                }
                            }

                            .box {
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 150px;
                                height: 40px;
                                margin-top: 20px;
                                margin-left: 50px;
                                line-height: 40px;
                                text-align: center;
                                font-size: 20px;
                                border-radius: 5px;
                                user-select: none;
                                cursor: pointer;
                                color: #409eff;
                            }
                        }
                    }

                    .btn {
                        width: 250px;
                        height: 76px;
                        line-height: 76px;
                        margin: 0 auto;
                        text-align: center;
                        background-color: #3f69ff;
                        color: #fff;
                        font-size: 30px;
                        border-radius: 100px;
                        margin-top: 100px;
                    }
                }
            }
        }
    }
}
</style>
